<template>
	<section :class="`detect_deviece_${deviceType} state_${deviceType}`">
		<HeadBar
			:title="title"
			v-show="!back || deviceType === 'desktop'" />
		<BackHead
			:title="backTitle"
			v-show="back && deviceType !== 'desktop'" />

		<wrapper v-show="wrapper" :color="color">
			<div class="main-wrapper">
				<slot />
			</div>
		</wrapper>
    <div class="main-wrapper" v-show="!wrapper">
				<slot />
			</div>
		<FootBar :title="title" />
	</section>
</template>

<script>
import BackHead from './back_head.vue'
import Wrapper from './wrapper.vue'
export default {
	components: {
		BackHead,
		Wrapper,
	},

	props: {
    color: {
      type: String,
      default: 'white'
    },
		wrapper: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: false,
			default: 'TodayinHistory',
		},
		backTitle: {
			type: String,
			required: false,
			default: '',
		},
		back: {
			type: Boolean,
			default: false,
			require: false,
		},
	},
}
</script>