import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			list: [],
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6466344935166719',
				ad1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '7267717209', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3152378222', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3980918749', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '3328472195', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad5: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '2015390521', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				ad6: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6466344935166719', 'data-ad-slot': '4038084486', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			showDebug: false,
			firebaseConfig: {
				apiKey: "AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo",
				authDomain: "webs-58a8d.firebaseapp.com",
				projectId: "webs-58a8d",
				storageBucket: "webs-58a8d.appspot.com",
				messagingSenderId: "730684174767",
				appId: "1:730684174767:web:bb6f4a647abdb5a80c3f5a",
				measurementId: "G-SJEJW119SL"
			  }
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
